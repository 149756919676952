import React, { useEffect, useState } from "react";
import { hotjar } from "react-hotjar";
import CheckoutWizard from "./components/CheckoutWizard";
import OrderStatus from "./components/OrderStatus";
import OrderStatusMobile from "./components/OrderStatusMobile";
import Footer from "./components/Footer";
import shopLogo from "./images/shopLogo.png";
import "./App.css";
import "@shopify/polaris/build/esm/styles.css";
import { useCheckoutContext } from "./context/CheckoutContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { fetchCart } from "api";
import ReactPixel from "react-facebook-pixel";
import Hotjar from '@hotjar/browser';

const App = () => {
  const { checkout, setCheckout } = useCheckoutContext();
  const [isLoading, setIsLoading] = useState(true);
  const advancedMatching = {};
  if (checkout.customer?.email) {
    advancedMatching.em = checkout.customer?.email;
  }

  ReactPixel.init("1215656903083802", advancedMatching, {
    autoConfig: true,
    debug: false,
  });
  ReactPixel.pageView();
  ReactPixel.fbq("track", "PageView");

  // Initialize Hotjar
  const siteId = 5217062;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);


  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const cartToken = params.get("cartToken");
        const shopUrl = params.get("shopUrl");
        const silentLoginToken = params.get("silentLoginToken");
        const referral = params.get("referral");
        const rc = params.get("rc");
        const enrollType = params.get("enrollType");

        const cartData = await fetchCart(
          cartToken,
          shopUrl,
          silentLoginToken,
          referral,
          rc
        );

        setCheckout((prevCheckout) => {
          return {
            ...prevCheckout,
            cart: { ...prevCheckout.cart, ...cartData.cart },
            shopifyCustomer: {
              ...prevCheckout.shopifyCustomer,
              ...cartData.shopifyCustomer,
            },
            customer: { ...prevCheckout.customer, ...cartData.customer },
            settings: { ...prevCheckout.customer, ...cartData.customSettings },
            cartToken: cartToken,
            orderPoints: cartData.orderPoints,
            shopUrl: shopUrl,
            customerToken: silentLoginToken,
            enrollType: enrollType,
          };
        });

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching cart:", error);
        setIsLoading(false);
      }
    };

    fetchCartData();

    // Initialize Hotjar
    // const siteId = 5217062;
    // const hotjarVersion = 6;
    // Hotjar.init(siteId, hotjarVersion);
  }, [setCheckout]);

  return (
    <Row className="h-100">
      <Col xs={12} md={6}>
        <div className="mainContainer leftContainer">
          <div className="wrapper marginBottomSmall">
            <img
              src={shopLogo}
              className="mb-3"
              alt="company-logo"
              style={{ maxWidth: "200px", maxHeight: "200px", height: "auto" }}
            />
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                <OrderStatusMobile cart={checkout.cart} />
                <div className="wizardContainer">
                  <CheckoutWizard cart={checkout} />
                </div>
              </>
            )}
            <div className="horizontalRuler marginTopMedium" />
            <Footer />
          </div>
        </div>
      </Col>
      {isLoading ? <div></div> : <OrderStatus cart={checkout.cart} />}
    </Row>
  );
};

export default App;
