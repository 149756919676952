import { useState, useEffect, useRef } from "react";

import Button from "react-bootstrap/Button";
import { useWizard } from "react-use-wizard";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ReactPixel from "react-facebook-pixel";

import { useCheckoutContext } from "context/CheckoutContext";
import SummaryTable from "components/SummaryTable";
import { Container } from "react-bootstrap";
import {
  recalculateCart,
  validateAddress,
  checkEmailExists,
  checkPhoneExists,
  IsWebaliasAvailable,
} from "api";
import Spinner from "react-bootstrap/Spinner";
import { SHOPIFY_LOGIN_URL } from "config";
import { regexEmail, regexPhone } from "../utils/regex";
import { emailExistsMessage, phoneExistsMessage } from "../utils/messages";
import { formatPhoneNumber } from "../utils/formaters";
import Select from "components/Select";

const ShippingAddress = ({ addresses = [], contact, referral }) => {
  const [validated, setValidated] = useState(false);
  const { nextStep } = useWizard();
  const [isCalculating, setCalculating] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const filteredAddresses = addresses.filter(
    (address) => address.address1 && address.address1.trim() !== ""
  );
  const [emailState, setEmailState] = useState({
    error: "",
    valid: "",
    isLoading: false,
  });
  const [phoneState, setPhoneState] = useState({
    error: "",
    valid: "",
    isLoading: false,
  });
  const [usernameState, setUsernameState] = useState({
    error: "",
    valid: "",
    isLoading: false,
  });
  const [newAddressState, setNewAddressState] = useState({
    error: "",
    valid: "",
    isLoading: false,
  });
  const { checkout, setCheckout } = useCheckoutContext();
  const [errorBirthDateMessage, setErrorBirthDateMessage] = useState("");
  const [isBirthDateInvalid, setIsBirthDateInvalid] = useState(false);
  const streetAddressRef = useRef(null);
  const cityRef = useRef(null);
  const zipRef = useRef(null);

  const isAmbassadorOrAffiliate =
    checkout.enrollType === "ambassador" || checkout.enrollType === "affiliate";
  const isAmbassador = checkout.enrollType === "ambassador";
  const currentDate = new Date();
  const currentDateString = `${currentDate.getFullYear()}-${(
    "0" +
    (currentDate.getMonth() + 1)
  ).slice(-2)}-${("0" + currentDate.getDate()).slice(-2)}`;

  const validateAddressAndUpdateState = async () => {
    setNewAddressState((prevState) => ({
      ...prevState,
      isLoading: true,
      error: "",
      valid: "",
    }));

    const { streetAddress, city, zip, state } = checkout.newAddress;

    if (!streetAddress || !city || !zip || !state) {
      setNewAddressState((prevState) => ({
        ...prevState,
        error: "Street Address, City, Zip Code, and State are required",
        isLoading: false,
      }));
      return false;
    }

    try {
      const validAddress = await validateAddress(
        checkout.shopUrl,
        streetAddress,
        city,
        zip
      );

      if (validAddress && validAddress.success) {
        setCheckout((prevState) => ({
          ...prevState,
          newAddress: {
            ...prevState.newAddress,
            streetAddress: validAddress.address,
            city: validAddress.city,
            zip: validAddress.zip,
            state: validAddress.state,
          },
        }));

        setNewAddressState((prevState) => ({
          ...prevState,
          valid: "Address verified successfully",
          error: "",
          isLoading: false,
        }));
        return true;
      } else {
        throw new Error("Invalid address");
      }
    } catch (error) {
      setNewAddressState((prevState) => ({
        ...prevState,
        error: error.message || "Error validating address",
        valid: "",
        isLoading: false,
      }));
      return false;
    }
  };

  const verifyAddress = async () => {
    await validateAddressAndUpdateState();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (
      checkout.shipping.newAddress !== null &&
      checkout.shipping.newAddress !== undefined &&
      !checkout.shipping.newAddress
    ) {
      await recalculateOrder(checkout);
      return;
    }

    setValidated(true);

    let isAddressValid = true;
    if (checkout.newAddress?.country === "US") {
      isAddressValid = await validateAddressAndUpdateState();
    }

    if (!isAddressValid) {
      return;
    }

    if (
      form.checkValidity() === false ||
      emailState.error ||
      usernameState.error
    ) {
      event.stopPropagation();
      return;
    }

    if (checkout.newAddress.password !== checkout.newAddress.confirmPassword) {
      setPasswordMatch(false);
      return;
    }

    setPasswordMatch(true);
    setCheckout((prevCheckout) => ({
      ...prevCheckout,
      shipping: prevCheckout.newAddress,
    }));
    await recalculateOrder(checkout);
  };

  const validateContact = async (type, value, setState) => {
    const regex = type === "email" ? regexEmail : regexPhone;

    setState((prevState) => ({ ...prevState, valid: "", error: "" }));

    if (!regex.test(value)) {
      return setState((prevState) => ({
        ...prevState,
        error: `Invalid ${type} format`,
      }));
    }

    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const checkExists =
        type === "email" ? checkEmailExists : checkPhoneExists;
      const data = await checkExists(value, checkout.shopUrl);

      if (data[`${type}Exists`] === 0) {
        setState((prevState) => ({
          ...prevState,
          valid: `${type.charAt(0).toUpperCase() + type.slice(1)} Valid`,
          error: "",
        }));
      } else {
        const message =
          type === "email" ? emailExistsMessage(value) : phoneExistsMessage;
        setState((prevState) => ({
          ...prevState,
          error: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: `Error validating ${type}`,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const handleRecalculateSuccess = (updatedCart) => {
    updateCheckoutState(updatedCart);
    setCalculating(false);
    const contentIds = updatedCart.orderItems?.map((item) => item.sku);
    const contents = updatedCart.orderItems?.map((item) => ({
      id: item.sku,
      quantity: item.quantity,
    }));
    ReactPixel.track("AddPaymentInfo", {
      content_ids: contentIds,
      contents: contents,
      currency: updatedCart?.currency,
      value: updatedCart?.orderTotal,
    });
    nextStep();
  };

  const updateCheckoutState = (updatedCart) => {
    setCheckout((prevCheckout) => ({
      ...prevCheckout,
      cart: {
        ...prevCheckout.cart,
        shippingCost: updatedCart.shippingCost,
        shippingTotal: updatedCart.shippingTotal,
        shippingTotalString: updatedCart.shippingTotalString,
        orderTotal: updatedCart.orderTotal,
        orderTotalString: updatedCart.orderTotalString,
        subTotal: updatedCart.subTotal,
        totalDiscountRcString: updatedCart.totalDiscountRcString,
        totalDiscountRC: updatedCart.totalDiscountRC,
        taxTotal: updatedCart.taxTotal,
        taxTotalString: updatedCart.taxTotalString,
        discountOrder: updatedCart.discountOrder,
      },
    }));
  };

  const handleRecalculateError = (error) => {
    console.error("Error:", error);
  };

  const recalculateOrder = async (e) => {
    try {
      setCalculating(true);
      const updatedCart = await recalculateCart(checkout);
      handleRecalculateSuccess(updatedCart);
    } catch (error) {
      handleRecalculateError(error);
    } finally {
      setCalculating(false);
    }
  };

  const pickShipping = (address) => {
    let newshipping = {
      firstName: address.firstName,
      lastName: address.lastName,
      phone: address.phone,
      country: address.country,
      streetAddress: address.address1,
      city: address.city,
      zip: address.zip,
      state: address.state,
      newAddress: false,
    };
    setCheckout({ ...checkout, shipping: newshipping });
  };

  useEffect(() => {
    setCheckout({ ...checkout, currentStep: 1 });

    if (checkout.newAddress == null) {
      setCheckout({
        ...checkout,
        newAddress: {
          email: "",
          firstName: "",
          lastName: "",
          username: "",
          birthDate: "",
          phone: "",
          password: "",
          loginEmail: "",
          confirmPassword: "",
          country: "US",
          streetAddress: "",
          city: "",
          zip: "",
          state: "",
          newAddress: true,
        },
      });
    } else if (!checkout.newAddress.country) {
      setCheckout({
        ...checkout,
        newAddress: {
          ...checkout.newAddress,
          country: "US",
        },
      });
    }
  }, []);

  const clearValidationStates = () => {
    setNewAddressState((prevState) => ({
      ...prevState,
      error: "",
      valid: "",
      isLoading: false,
    }));
    if (streetAddressRef.current) {
      streetAddressRef.current.setCustomValidity("");
    }
  };

  return (
    <Container>
      <div className="wizardContainer">
        <button className="wizardLink">Cart</button>
        {">"}
        <button className="wizardLink">Account Details</button>
        {">"}
        <button className="wizardLink">Shipping Address</button>
        {">"}
        <button className="wizardLink" disabled>
          Payment
        </button>
      </div>

      <SummaryTable contact={contact} referral={referral} />

      {filteredAddresses.length > 0 && (
        <Container className="p-0 my-3">
          {filteredAddresses.length > 0 && (
            <Row>
              <Col md={12} xs={12}>
                <h5>Select Your Shipping Address</h5>
              </Col>
              <Col xs={12}>
                <div className="horizontalRuler my-2" />
              </Col>
            </Row>
          )}
          <Row>
            {filteredAddresses.map((address, index) => (
              <div key={index}>
                <Row>
                  <Col md={12} xs={12}>
                    <Form.Check
                      type="radio"
                      name="address"
                      id={`address-${index}`}
                      inline
                      onChange={() => pickShipping(address)}
                      checked={
                        checkout.shipping.streetAddress === address.address1
                      }
                    />
                    {address.country}, {address.city}, {address.address1}
                  </Col>
                  <Col xs={12}>
                    <div className="horizontalRuler my-2" />
                  </Col>
                </Row>
              </div>
            ))}
          </Row>
          {checkout.newAddress &&
            (checkout.newAddress.country ||
              checkout.newAddress.city ||
              checkout.newAddress.streetAddress) && (
              <Row>
                <Col md={12} xs={12}>
                  <Form.Check
                    type="radio"
                    name="address"
                    id={`address-new`}
                    inline
                    onChange={(e) => {
                      setCheckout((prevCheckout) => ({
                        ...prevCheckout,
                        shipping: prevCheckout.newAddress,
                      }));
                    }}
                    checked={
                      checkout.shipping.streetAddress ===
                      checkout.newAddress.streetAddress
                    }
                  />
                  {checkout.newAddress.country}, {checkout.newAddress.city},{" "}
                  {checkout.newAddress.streetAddress}
                </Col>
                <Col xs={12}>
                  <div className="horizontalRuler my-2" />
                </Col>
              </Row>
            )}
          <Row className="mt-5">
            <Col>
              <h5> Enter New Address</h5>
              <div className="horizontalRuler my-2" />
            </Col>
          </Row>
        </Container>
      )}

      <Container className="p-0">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {checkout.customerToken ? null : (
            <>
              <Row>
                <Col md={12} xs={12}>
                  <p>
                    {"Already have an account? "}
                    <a
                      href={SHOPIFY_LOGIN_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="oola-main-text-color"
                      style={{
                        width: "100%",
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      Login
                    </a>
                  </p>
                </Col>
              </Row>
              <div className="box marginBottomSmall">
                <Row>
                  <Col md={9} xs={12}>
                    <h2 className="">
                      {isAmbassadorOrAffiliate
                        ? `Create Your ${
                            checkout.enrollType === "ambassador"
                              ? "Ambassador"
                              : "Affiliate"
                          } Account`
                        : "Create Your Account"}
                    </h2>
                  </Col>
                </Row>
                {checkout.newAddress && (
                  <Row>
                    <Col xs="12" md="12">
                      {isAmbassadorOrAffiliate ? (
                        <>
                          <FloatingLabel
                            controlId="floatingUsername"
                            label="Username"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              value={checkout.newAddress.username}
                              onChange={(e) => {
                                const username = e.target.value;
                                if (username.includes(" ")) {
                                  setUsernameState({
                                    valid: "",
                                    error: "Username should not contain spaces",
                                    isLoading: false,
                                  });
                                } else {
                                  setCheckout({
                                    ...checkout,
                                    newAddress: {
                                      ...checkout.newAddress,
                                      username: username,
                                    },
                                  });
                                }
                              }}
                              onBlur={async () => {
                                setUsernameState({
                                  valid: "",
                                  error: "",
                                  isLoading: true,
                                });
                                const result = await IsWebaliasAvailable(
                                  checkout.newAddress.username,
                                  checkout.shopUrl
                                );
                                setUsernameState(result);
                              }}
                              isInvalid={!!usernameState.error}
                              required
                            />
                            {usernameState.isLoading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="m-2"
                              />
                            ) : (
                              usernameState.valid && (
                                <p style={{ color: "green", margin: "4px" }}>
                                  {usernameState.valid}
                                </p>
                              )
                            )}
                            <Form.Control.Feedback type="invalid">
                              {usernameState.error}
                            </Form.Control.Feedback>
                            {checkout.enrollType === "ambassador" && (
                              <p className="user-login-url-form">
                                https://oolalife.com/?ref=
                                {checkout.newAddress.username}
                              </p>
                            )}
                          </FloatingLabel>
                          {isAmbassador && (
                            <FloatingLabel
                              controlId="floatingBirthDate"
                              label="Birth Date"
                              className="mb-3"
                            >
                              <Form.Control
                                type="date"
                                value={checkout.newAddress.birthDate}
                                onChange={(e) => {
                                  const selectedDate = new Date(e.target.value);
                                  const currentDate = new Date();
                                  const adultDate = new Date(
                                    currentDate.getFullYear() - 18,
                                    currentDate.getMonth(),
                                    currentDate.getDate()
                                  );

                                  setCheckout({
                                    ...checkout,
                                    newAddress: {
                                      ...checkout.newAddress,
                                      birthDate: e.target.value,
                                    },
                                  });

                                  if (selectedDate <= adultDate) {
                                    setErrorBirthDateMessage(""); // clear the error message
                                    setIsBirthDateInvalid(false); // set isBirthDateInvalid to false
                                  } else {
                                    setErrorBirthDateMessage(
                                      "You must be at least 18 years old."
                                    );
                                    setIsBirthDateInvalid(true); // set isBirthDateInvalid to true
                                  }
                                }}
                                min="1924-01-02"
                                max={currentDateString}
                                required
                                isInvalid={isBirthDateInvalid} // add isInvalid attribute
                              />
                              <Form.Control.Feedback type="invalid">
                                {errorBirthDateMessage}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          )}
                        </>
                      ) : (
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Email"
                          className="mb-3"
                        >
                          <Form.Control
                            type="email"
                            value={checkout.newAddress.loginEmail}
                            onChange={(e) =>
                              setCheckout({
                                ...checkout,
                                newAddress: {
                                  ...checkout.newAddress,
                                  loginEmail: e.target.value,
                                },
                              })
                            }
                            onBlur={() =>
                              validateContact(
                                "email",
                                checkout.newAddress.loginEmail,
                                setEmailState
                              )
                            }
                            isInvalid={!!emailState.error}
                            required
                          />
                          {emailState.isLoading ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="m-2"
                            />
                          ) : (
                            emailState.valid && (
                              <p style={{ color: "green", margin: "4px" }}>
                                {emailState.valid}
                              </p>
                            )
                          )}
                          <Form.Control.Feedback type="invalid">
                            {emailState.error}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      )}
                    </Col>
                    <Col xs="12" md="6">
                      <Form.Group controlId="password">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Password"
                          className="mb-3"
                        >
                          <Form.Control
                            type="password"
                            value={checkout.newAddress.password}
                            onChange={(e) =>
                              setCheckout({
                                ...checkout,
                                newAddress: {
                                  ...checkout.newAddress,
                                  password: e.target.value,
                                },
                              })
                            }
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter a password.
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col xs="12" md="6">
                      <Form.Group controlId="confirmPassword">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Confirm Password"
                          className="mb-3"
                        >
                          <Form.Control
                            type="password"
                            value={checkout.newAddress.confirmPassword}
                            onChange={(e) => {
                              const confirmPassword = e.target.value;
                              setCheckout({
                                ...checkout,
                                newAddress: {
                                  ...checkout.newAddress,
                                  confirmPassword: confirmPassword,
                                },
                              });
                              setPasswordMatch(
                                checkout.newAddress.password === confirmPassword
                              );
                            }}
                            isInvalid={!passwordMatch}
                            pattern={checkout.newAddress.password} // Use the pattern attribute to match the password
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {passwordMatch
                              ? "Please confirm your password."
                              : "Passwords do not match."}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            </>
          )}

          {checkout.newAddress && (
            <div className="box marginBottomSmall">
              <h2 className="marginBottomSmall marginTopSmall">
                Shipping Address
              </h2>
              <Row>
                <Col xs="12" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="First Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      value={checkout.newAddress.firstName}
                      onChange={(e) =>
                        setCheckout({
                          ...checkout,
                          newAddress: {
                            ...checkout.newAddress,
                            firstName: e.target.value,
                          },
                        })
                      }
                      maxLength="40"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      First Name is required
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col xs="12" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      value={checkout.newAddress.lastName}
                      onChange={(e) =>
                        setCheckout({
                          ...checkout,
                          newAddress: {
                            ...checkout.newAddress,
                            lastName: e.target.value,
                          },
                        })
                      }
                      maxLength="40"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Last Name is required
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  value={checkout.newAddress.email}
                  onChange={(e) =>
                    setCheckout({
                      ...checkout,
                      newAddress: {
                        ...checkout.newAddress,
                        email: e.target.value,
                      },
                    })
                  }
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Phone"
                className="mb-3"
              >
                <Form.Control
                  type="tel"
                  value={checkout.newAddress.phone}
                  onChange={(e) => {
                    const formattedPhoneNumber = formatPhoneNumber(
                      e.target.value
                    );
                    setCheckout({
                      ...checkout,
                      newAddress: {
                        ...checkout.newAddress,
                        phone: formattedPhoneNumber,
                      },
                    });
                  }}
                  onBlur={() =>
                    validateContact(
                      "phone",
                      checkout.newAddress.phone,
                      setPhoneState
                    )
                  }
                  isInvalid={!!phoneState.error}
                  required
                />
                {phoneState.isLoading ? (
                  <Spinner animation="border" size="sm" className="m-2" />
                ) : (
                  phoneState.valid && (
                    <p style={{ color: "green", margin: "4px" }}>
                      {phoneState.valid}
                    </p>
                  )
                )}
                <Form.Control.Feedback type="invalid">
                  {phoneState.error}
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingSelect"
                label="Country"
                className="mb-3"
              >
                <Form.Select
                  aria-label="Country select"
                  value={checkout.newAddress.country || "US"} // Bind value to state
                  onChange={(e) =>
                    setCheckout({
                      ...checkout,
                      newAddress: {
                        ...checkout.newAddress,
                        country: e.target.value,
                      },
                    })
                  }
                >
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="State"
                className="mb-3"
              >
                <Select
                  name="state"
                  value={checkout.newAddress.state}
                  className="payment-form-select"
                  collection={
                    checkout.newAddress.country === "CA"
                      ? "caProvince"
                      : "usState"
                  } // Conditional collection
                  updateContext={(target, value) =>
                    setCheckout({
                      ...checkout,
                      newAddress: {
                        ...checkout.newAddress,
                        state: value,
                      },
                    })
                  }
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Street Address"
                className="mb-3"
              >
                <Form.Control
                  ref={streetAddressRef}
                  type="text"
                  autoComplete="street-address"
                  value={checkout.newAddress.streetAddress}
                  onChange={(e) => {
                    clearValidationStates();
                    setCheckout({
                      ...checkout,
                      newAddress: {
                        ...checkout.newAddress,
                        streetAddress: e.target.value,
                      },
                    });
                  }}
                  required
                  isInvalid={!!newAddressState.error}
                  isValid={!newAddressState.error && !!newAddressState.valid}
                />
                <Form.Control.Feedback type="invalid">
                  {newAddressState.error || "Street Address is required"}
                </Form.Control.Feedback>
                {!newAddressState.error && newAddressState.valid && (
                  <Form.Control.Feedback type="valid">
                    {newAddressState.valid}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>

              <Row>
                <Col xs="12" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="City"
                    className="mb-3"
                  >
                    <Form.Control
                      ref={cityRef}
                      type="text"
                      autoComplete="address-level2"
                      value={checkout.newAddress.city}
                      onChange={(e) => {
                        clearValidationStates();
                        setCheckout({
                          ...checkout,
                          newAddress: {
                            ...checkout.newAddress,
                            city: e.target.value,
                          },
                        });
                      }}
                      required
                      isInvalid={!!newAddressState.error}
                    />
                    <Form.Control.Feedback type="invalid" />
                  </FloatingLabel>
                </Col>
                <Col xs="12" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Zipcode"
                    className="mb-3"
                  >
                    <Form.Control
                      ref={zipRef}
                      type="text"
                      autoComplete="postal-code"
                      value={checkout.newAddress.zip}
                      onChange={(e) => {
                        clearValidationStates();
                        setCheckout({
                          ...checkout,
                          newAddress: {
                            ...checkout.newAddress,
                            zip: e.target.value,
                          },
                        });
                      }}
                      required
                      isInvalid={!!newAddressState.error}
                    />
                    <Form.Control.Feedback type="invalid" />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
          )}
          <div className="flex JCEnd">
            {checkout.newAddress?.country === "US" && (
              <Button
                className="btn-bold secondary-color mx-3"
                variant="secondary"
                onClick={() => verifyAddress()}
                disabled={newAddressState.isLoading}
              >
                {newAddressState.isLoading && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                Verify Address
              </Button>
            )}
            <Button
              className="btn-bold oola-main-color"
              variant="primary"
              type="submit"
              disabled={isCalculating}
            >
              {isCalculating && (
                <Spinner animation="border" size="sm" className="me-2" />
              )}
              Ship to this address
            </Button>
          </div>
        </Form>
      </Container>
    </Container>
  );
};

export default ShippingAddress;
