import { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { useCheckoutContext } from "context/CheckoutContext";

const OrderStatus = ({ cart }) => {
  const { checkout, setCheckout } = useCheckoutContext();
  const { currentStep } = checkout;
  const [currentOrderTotal, setCurrentOrderTotal] = useState(0);

  const shippingValue = () => {
    return currentStep === 1
      ? "Calculated at next step"
      : checkout.cart.shippingTotalString;
  };
  const discountPointsValue = () => {
    return checkout.applyPoints
      ? `-$ ${currentPoinTotal().toFixed(2)}`
      : "$0.00";
  };

  const discountValue = () => {
    return currentStep === 1
      ? "Calculated at next step"
      : checkout.cart.totalDiscountRcString;
  };

  const discountOrderValue = () => {
    return currentStep === 1
      ? "Calculated at next step"
      : checkout.cart.discountOrder > 0
        ? `-$ ${checkout.cart.discountOrder.toFixed(2)}`
        : "$0.00";
  };

  const taxValue = () => {
    return currentStep === 1
      ? "Calculated at next step"
      : checkout.cart.taxTotalString;
  };

  const currentPoinTotal = () => {
    const totalAmount = parseFloat(cart.subTotalString.replace("$", ""));
    const discountPoints =
      cart.pointsTotal > totalAmount ? totalAmount : cart.pointsTotal;
    return checkout.applyPoints ? discountPoints : 0;
  };

  useEffect(() => {
    const newTotal =
      parseFloat(checkout.cart.subTotalString.replace(/[^0-9.-]+/g, "")) -
      currentPoinTotal() +
      checkout.cart.shippingTotal +
      checkout.cart.taxTotal +
      (checkout.cart.totalDiscountRC || 0) -
      (checkout.cart.discountOrder || 0);
    setCurrentOrderTotal(newTotal > 0 ? newTotal : 0);
  }, [checkout.cart, checkout.applyPoints]);

  return (
    <Col xs={12} md={6} className="d-none d-sm-block">
      <div className="mainContainer rightContainer">
        <Col xs={12} md={6}>
          <div className="wrapper ">
            <h4 className="grayFont">Today's Order</h4>
            <div className="horizontalRuler" />

            <table className="productTable">
              <tbody>
                {cart.orderItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="productImgContainer">
                        <img
                          className="productImg"
                          src={item.imageUrl}
                          alt="product-img"
                        />
                        <span className="productQuantity">
                          {item.quantity.toFixed(0)}
                        </span>
                      </div>
                    </td>
                    <td className="grayFont truncate-md product-title">
                      <b>{item.productTitle}</b>
                    </td>
                    <td className="grayFont">
                      <b>{item.priceString}</b>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {cart.orderItems.length > 0 && (
              <Row className="align-items-center mb-3">
                <Col xs={12}>
                  <div className="horizontalRuler my-3" />
                </Col>
                <Col xs={6} md={9}>
                  OOLA MOOLA: <b>${cart.pointsTotal.toFixed(2)}</b>
                </Col>
                {cart.pointsTotal > 0 && (
                  <Col xs={6} md={3}>
                    <Button
                      className={`btn-bold btn-sm px-4 ${
                        checkout.applyPoints
                          ? "btn-secondary"
                          : "oola-main-color"
                      } float-end`}
                      variant="primary"
                      disabled={cart.pointsTotal <= -2}
                      onClick={() =>
                        setCheckout({
                          ...checkout,
                          applyPoints: !checkout.applyPoints,
                        })
                      }
                    >
                      {checkout.applyPoints ? "APPLIED" : "APPLY"}
                    </Button>
                  </Col>
                )}
                <Col xs={12}>
                  <div className="horizontalRuler my-3" />
                </Col>
              </Row>
            )}

            {cart.autoOrderItems.length > 0 && (
              <>
                <h4 className="grayFont">Subscription Order</h4>
                <div className="horizontalRuler" />
                <table className="productTable">
                  <tbody>
                    {cart.autoOrderItems.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="productImgContainer">
                            <img
                              className="productImg"
                              src={item.imageUrl}
                              alt="product-img"
                            />
                            <span className="productQuantity">
                              {item.quantity.toFixed(0)}
                            </span>
                          </div>
                        </td>
                        <td className="grayFont truncate-md product-title">
                          <b>{item.productTitle}</b>
                        </td>
                        <td className="grayFont">
                          <b>${item.price.toFixed(2)}</b>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}

            <div className="horizontalRuler" />
            <table className="productPriceTable">
              <tbody>
                <tr>
                  <td>Sub Total</td>
                  <td>
                    <b>{cart.subTotalString}</b>
                  </td>
                </tr>
                {checkout.applyPoints && (
                  <tr>
                    <td>Discount OOLA MOOLA</td>
                    <td>{discountPointsValue()}</td>
                  </tr>
                )}
                {checkout.cart.totalDiscountRC < 0 && (
                  <tr>
                    <td>Discount GET10</td>
                    <td>{discountValue()}</td>
                  </tr>
                )}
                {checkout.cart.discountOrder > 0 && (
                  <tr>
                    <td>Multiple Event Discount</td>
                    <td>{discountOrderValue()}</td>
                  </tr>
                )}
                <tr>
                  <td>Shipping</td>
                  <td>{shippingValue()}</td>
                </tr>
                {currentStep > 0 && (
                  <tr>
                    <td>Tax</td>
                    <td>{taxValue()}</td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="horizontalRuler" />
            <div className="priceGridContainer">
              <div className="totalLabelRow">Total</div>
              <div className="totalRow">
                {currentOrderTotal.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
            </div>
          </div>
        </Col>
      </div>
    </Col>
  );
};

export default OrderStatus;
