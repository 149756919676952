import { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { BsCart } from "react-icons/bs";

import { useCheckoutContext } from "context/CheckoutContext";

const OrderStatusMobile = ({ cart }) => {
  const [toggle, setToggle] = useState(false);
  const { checkout, setCheckout } = useCheckoutContext();
  const { currentStep } = checkout;
  const [currentOrderTotal, setCurrentOrderTotal] = useState(0);

  const shippingValue = () => {
    return currentStep === 1
      ? "Calculated at next step"
      : cart.shippingTotalString;
  };

  const discountPointsValue = () => {
    return checkout.applyPoints
      ? `-$ ${currentPoinTotal().toFixed(2)}`
      : "$0.00";
  };

  const discountOrderValue = () => {
    return currentStep === 1
      ? "Calculated at next step"
      : checkout.cart.discountOrder > 0
        ? `-$ ${checkout.cart.discountOrder.toFixed(2)}`
        : "$0.00";
  };

  const discountValue = () => {
    return currentStep === 1
      ? "Calculated at next step"
      : checkout.cart.totalDiscountRcString;
  };

  const taxValue = () => {
    return currentStep === 1 ? "Calculated at next step" : cart.taxTotalString;
  };

  const currentPoinTotal = () => {
    const totalAmount = parseFloat(cart.subTotalString.replace("$", ""));
    const discountPoints =
      cart.pointsTotal > totalAmount ? totalAmount : cart.pointsTotal;
    return checkout.applyPoints ? discountPoints : 0;
  };

  useEffect(() => {
    const newTotal =
      parseFloat(checkout.cart.subTotalString.replace(/[^0-9.-]+/g, "")) -
      currentPoinTotal() +
      checkout.cart.shippingTotal +
      checkout.cart.taxTotal +
      (checkout.cart.totalDiscountRC || 0) -
      (checkout.cart.discountOrder || 0);
    setCurrentOrderTotal(newTotal > 0 ? newTotal : 0);
  }, [checkout.cart, checkout.applyPoints]);

  return (
    <Col
      xs={12}
      md={6}
      className="fixPadding d-md-none .d-lg-block .d-lg-none .d-xl-block .d-md-none .d-lg-block"
    >
      <div className="mainContainer newClass">
        <div className="flex flexColumn JCSpaceAround w100">
          {toggle ? (
            <>
              <div className="flex JCSpaceAround w100">
                <div>
                  <BsCart />
                </div>
                <div>
                  <button
                    className="transparent"
                    onClick={() => {
                      setToggle(false);
                    }}
                  >
                    Hide Cart Content <BsChevronUp />
                  </button>
                </div>
                <div>
                  <b>
                    {currentOrderTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </b>
                </div>
              </div>

              <div className="wrapperXs">
                <h4 className="grayFont mt-2">Today's Order</h4>
                <div className="horizontalRuler" />
                <table className="productTable smallTable">
                  <tbody>
                    {cart.orderItems.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="productImgContainer">
                            <img
                              className="productImg"
                              src={item.imageUrl}
                              alt="product-img"
                            />
                            <span className="productQuantity">
                              {item.quantity.toFixed(0)}
                            </span>
                          </div>
                        </td>
                        <td className="grayFont product-title">
                          <b>{item.productTitle}</b>
                        </td>
                        <td className="grayFont">
                          <b>{item.priceString}</b>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {cart.orderItems.length > 0 && (
                  <Row className="align-items-center mb-3">
                    <Col xs={12}>
                      <div className="horizontalRuler my-3" />
                    </Col>
                    <Col xs={6} md={6}>
                      OOLA MOOLA: <b>${cart.pointsTotal.toFixed(2)}</b>
                    </Col>
                    {cart.pointsTotal > 0 && (
                      <Col xs={6} md={6}>
                        <Button
                          className={`btn-bold btn-sm px-4 ${checkout.applyPoints ? "btn-secondary" : "oola-main-color"} float-end`}
                          variant="primary"
                          disabled={cart.pointsTotal <= -2}
                          onClick={() =>
                            setCheckout({
                              ...checkout,
                              applyPoints: !checkout.applyPoints,
                            })
                          }
                        >
                          {checkout.applyPoints ? "APPLIED" : "APPLY"}
                        </Button>
                      </Col>
                    )}
                    <Col xs={12}>
                      <div className="horizontalRuler my-3" />
                    </Col>
                  </Row>
                )}

                {cart.autoOrderItems.length > 0 && (
                  <>
                    <h4 className="grayFont mt-2">Subscription Order</h4>
                    <div className="horizontalRuler" />
                    <table className="productTable smallTable">
                      <tbody>
                        {cart.autoOrderItems.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="productImgContainer">
                                <img
                                  className="productImg"
                                  src={item.imageUrl}
                                  alt="product-img"
                                />
                                <span className="productQuantity">
                                  {item.quantity.toFixed(0)}
                                </span>
                              </div>
                            </td>
                            <td className="grayFont product-title">
                              <b>{item.productTitle}</b>
                            </td>
                            <td className="grayFont">
                              <b>{item.priceString}</b>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}

                <div className="horizontalRuler" />
                <table className="productPriceTable">
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td>
                        <b>{cart.subTotalString}</b>
                      </td>
                    </tr>
                    {checkout.applyPoints && (
                      <tr>
                        <td>Discount OOLA MOOLA</td>
                        <td>{discountPointsValue()}</td>
                      </tr>
                    )}
                    {checkout.cart.totalDiscountRC < 0 && (
                      <tr>
                        <td>Discount GET10</td>
                        <td>{discountValue()}</td>
                      </tr>
                    )}
                    {checkout.cart.discountOrder > 0 && (
                      <tr>
                        <td>Multiple Event Discount</td>
                        <td>{discountOrderValue()}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Shipping</td>
                      <td>{shippingValue()}</td>
                    </tr>
                    {currentStep > 0 && (
                      <tr>
                        <td>Tax</td>
                        <td>{taxValue()}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="horizontalRuler" />
                <div className="priceGridContainer">
                  <div className="totalLabelRow">Total</div>
                  <div className="taxRow">Tax calculated at next step</div>
                  <div className="totalRow">
                    {currentOrderTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex JCSpaceAround w100">
              <div>
                <BsCart />
              </div>
              <div>
                <button
                  className="transparent"
                  onClick={() => {
                    setToggle(true);
                  }}
                >
                  Show Cart Content <BsChevronDown />
                </button>
              </div>
              <div>
                <b>
                  {currentOrderTotal.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </b>
              </div>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

export default OrderStatusMobile;
